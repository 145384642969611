import axios from "axios";
import store from "../store";

const baseUrl = "https://" + window.location.hostname + "/api";
// const baseUrl = "http://" + window.location.hostname + ":3002/api";

export async function post(event, param) {
  try {
    let token = store.getters.app.user.token || '';

    // Добавляем token в param перед сериализацией
    const jsonBody = JSON.stringify({ ...param, token }) || {};
    const data = await go(event, jsonBody, "application/json");

    if (data && data.user) {
      store.dispatch("userData", data.user);
    }

    return data;
  } catch (error) {
    // Используем Vue Toast для отображения ошибки
    if (this.$toast) {
      this.$toast.show(`Error: ${error.message || error}`, { type: "error" });
    } else {
      console.error("Error:", error);
    }
    return;
  }
}

async function go(url, body, type) {
  try {
    const response = await axios.post(baseUrl + url, body, {
      headers: {
        ...(type && { "Content-Type": type }),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return { error: error.message || "An error occurred" };
  }
}
