import { createApp } from 'vue';
import store from "./store";
import {appMixin} from "./mixins/app";
import App from './App.vue';
import router from './router.js';

import i18n from "./global/lang.js";
import { modal } from "./global/Modal.js";
import { post } from "./global/post";
import { formatDate } from "./global/dateUtils";
import { num } from "./global/num";
import { GlobalMethods } from "./global/main.js";

import Toaster from './components/toaster';
import Sidebar from "./components/app/Sidebar";
import Modal from "./components/app/Modal";
import Dropdown from './components/app/Dropdown.vue';
import Navbar from "./components/app/Navbar";


const app = createApp(App);
app.mixin(appMixin);

app.config.globalProperties.$modal = modal;
app.config.globalProperties.$post = post;
app.config.globalProperties.$num = num;
app.config.globalProperties.$formatDate = formatDate;
GlobalMethods.$modal = modal;

app.use(store);
app.use(router);
app.use(Toaster);

app.use(i18n);
app.component("Modal", Modal);
app.component("Dropdown", Dropdown);
app.component("Sidebar", Sidebar);
app.component("Navbar", Navbar);

app.mount('#app');