<template>
  <div v-if="app.auth || load" class="container">
    <div>
      <Navbar />
      <toastr />
    </div>
    <div class="pr-3 pl-3">
      <router-view />
    </div>
    <Sidebar />
  </div>
  <div v-else class="loading-screen">
    <!-- Здесь можно добавить анимацию загрузки или сообщение -->
    Loading...
    <div style="display: none">
      <img v-for="src in sources" :src="src" :key="src" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sources: [],
      load: false,
    };
  },
  methods: {
    preloadImages(sources) {
      return new Promise((resolve, reject) => {
        let loadedCount = 0;
        const images = sources.map((src) => {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            loadedCount += 1;
            if (loadedCount === sources.length) {
              resolve();
            }
          };
          img.onerror = reject;
          return img;
        });
      });
    },
    importAll(r) {
      return r.keys().map(r);
    },
  },
  mounted() {
    // Импорт всех изображений из папки assets, включая вложенные папки
    const images = this.importAll(require.context("@/assets", true, /\.(png|jpe?g|svg)$/));
    this.sources = images.map((image) => image.default);

    this.preloadImages(this.sources)
      .then(() => {
        this.load = true;
      })
      .catch((error) => {
        console.error("Error preloading images:", error);
        this.load = true; // Установить в true даже при ошибке, чтобы избежать бесконечной загрузки
      });
  },
};
</script>

<style scoped>
@import "assets/css/bootstrap-icons/bootstrap-icons.css";
@import "assets/css/root.css";
@import "assets/css/style.css";
@import "assets/css/icon.css";
@import "assets/css/slider.css";

/* Добавьте стили для загрузочного экрана */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
}
</style>
