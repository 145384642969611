const data = {
  player: {
    1: { 1: 100, 2: 150, 3: 225, 4: 337, 5: 506 },
    2: { 1: 506, 2: 759, 3: 1139, 4: 1708, 5: 2562 },
    3: { 1: 2562, 2: 3844, 3: 5766, 4: 8649, 5: 12974 },
    4: { 1: 12974, 2: 19461, 3: 29192, 4: 43789, 5: 65684 },
    5: { 1: 65684, 2: 98526, 3: 147789, 4: 221683, 5: 332525 },
    6: { 1: 332525, 2: 498788, 3: 748182, 4: 1122274, 5: 1683411 },
    7: { 1: 1683411, 2: 2525116, 3: 3787674, 4: 5681512, 5: 8522268 },
    8: { 1: 8522268, 2: 12783402, 3: 19175103, 4: 28762655, 5: 43143982 },
    9: { 1: 43133982, 2: 64715974, 3: 97073961, 4: 145610942, 5: 218416413 },
  },
  playerPrice: {
    1: { 2: 200, 3: 400, 4: 800, 5: 1600 },
    2: { 2: 3200, 3: 6400, 4: 12800, 5: 25600 },
    3: { 2: 51200, 3: 102400, 4: 204800, 5: 409600 },
    4: { 2: 819200, 3: 1638400, 4: 3276800, 5: 6553600 },
    5: { 2: 13107200, 3: 26214400, 4: 52428800, 5: 104857600 },
    6: { 2: 209715200, 3: 419430400, 4: 838860800, 5: 1677721600 },
    7: { 2: 3355443200, 3: 6710886400, 4: 13421772800, 5: 26843545600 },
    8: { 2: 53687091200, 3: 107374182400, 4: 214748364800, 5: 429496729600 },
    9: { 2: 858993459200, 3: 1717986918400, 4: 3435973836800, 5: 6871947673600 },
  },
  rang: {
    1: { name: "Neophyte", xp: 100 },
    2: { name: "Challenger", xp: 1000 },
    3: { name: "Fighter", xp: 10000 },
    4: { name: "Strategist", xp: 100000 },
    5: { name: "Commander", xp: 1000000 },
    6: { name: "Leader", xp: 10000000 },
    7: { name: "Overlord", xp: 100000000 },
    8: { name: "Warlord", xp: 1000000000 },
    9: { name: "Icon", xp: 1000000000 },
  },
  perk: [{ img: "", type: "", bonus: "" }],
  top: {
    1: 10000000,
    2: 4000000,
    3: 1000000,
    4: 500000,
    5: 300000,
    6: 200000,
    7: 100000,
    8: 80000,
    9: 70000,
    10: 60000,
    100: 50000
  }
};

export default data;