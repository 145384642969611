<template>
  <div class="navbar">
    <div class="">
      <router-link to="/"><i class="icon bg-white x50 i-profile" style="border-radius: 30px"></i></router-link>
    </div>
    <div class="d-flex">
      <router-link to="/wallet" class="mr-3"><div class="nav-jksdn"><i class="icon br-50 x50 i-wallet"></i></div></router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    lang(lang) {
      this.$cookies.set("lang", lang, {
        path: "/",
        secure: true,
        sameSite: "strict",
      });

      if (Object.keys(this.$i18n.messages[lang]).length > 0) {
        this.$i18n.locale = lang;
      } else {
        this.$i18n.locale = "en";
        this.$i18n.silentFallbackWarn = true;
      }
      this.$modal("langModal");
    },
  },
};
</script>
