<template>
  <transition name="modal-slide-up">
    <div class="modal fade" @click="this.$modal(id)" :id="id">
      <div class="modal-dialog" role="document" @click.stop>
        <div class="modal-content">
          <a @click="this.$modal(id)" class="close" data-bs-dismiss="modal"><em class="bi bi-x"></em></a>
          <div class="modal-body modal-body-md">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["id"],
};
</script>

<style scoped>
.modal {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: rgba(108, 108, 108, 0.247);
    transition: opacity 0.3s ease, visibility 0s 0.3s;
    z-index: 99999;
}

.modal.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.modal-dialog {
    background: #000000;
    border-radius: 20px 20px 0 0;
    border-top: 2px solid #d4c300;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 100%;
    max-width: 500px;
    min-height: 50%;
    max-height: 80%;
    transition: transform 0.2s ease-in-out;
    overflow: hidden;
}

.modal.show .modal-dialog {
    transform: translate(-50%, 0);
}

.modal-content {
    height: 100%;
}

.modal-content .close {
  position: absolute;
  right: 0px;
  padding: 0 8px;
  font-size: 25px;
}
</style>
