import data from "./data";
export default {
  state: {
    user: {
      token: "",
      progress: 0,
    },
    auth: false,
  },
  mutations: {
    SET_RUN(state, newData) {
      state.user = newData;
      state.auth = true;
    },
    UPDATE_USER(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.user[key] = payload[key];
      });
    },
    UPDATE_CLAIM(state, t = 0) {
      if (t == 0) {
        state.user.gold += state.user.mine;
        state.user.mine = 0;
      }

      while (true) {
        let gold = state.user.gold ?? 0;
        let pr = (gold / data.rang[state.user.lvl + 1].xp) * 100;
        state.user.progress = pr < 0 ? 0 : pr;

        if (pr >= 100) {
          state.user.lvl += 1;
        } else {
          break;
        }
      }
    },
    UPDATE_MINE(state) {
      // Вычисляем доход за одну секунду
      const incomePerSecond = state.user.per / 3600;
      state.user.date += 1;
      let diffInSeconds = Math.floor(state.user.date - state.user.who); // разница во времени в секундах
      const currentBalance = Math.floor(diffInSeconds * incomePerSecond);

      // Обновляем баланс
      if (currentBalance > 0) {
        state.user.mine += currentBalance;
        state.user.who = state.user.date;
      }
    },
    UPDATE_UPGRADE(state, id) {
      if (state.user.playerData[id] < 5) {
        state.user.playerData[id] += 1;
        state.user.gold -= data.playerPrice[id][state.user.playerData[id]];
      }
    },
  },
  actions: {
    async run({ commit }, newData) {
      commit("SET_RUN", newData);
      commit("UPDATE_MINE");
      commit("UPDATE_CLAIM",1);
      setInterval(() => {
        commit("UPDATE_MINE");
      }, 1000);
    },
    async updateUser({ commit }, newData) {
      commit("UPDATE_USER", newData);
    },
    async claim({ commit }) {
      commit("UPDATE_CLAIM");
    },
    async upgrade({ commit }, id) {
      commit("UPDATE_UPGRADE", id);
    },
  },
  getters: {
    app(state) {
      const user = state.user;
      const auth = state.auth;
      return { user, auth, data };
    },
  },
};
